import EmaInput from "components/emaInput/EmaInput";

import "./FileUpload.sass";
import CountryRegionInput from "components/country-region-input/CountryRegionInput";
import findError from "utils/findError";
import DateTimeInput from "components/datetime-input/DateTimeInput";
function OperatorForm({
  operator,
  handleChange,
  errors,
  disableAuthorityType,
  t,
}) {
  return (
    <>
      <div className="ema-card">
        <div className="ema-card-title">{t("Basic")}</div>
        <div className="row">
          <div className="col col-6">
            <EmaInput
              name="company"
              label={t("Company Name")}
              value={operator.company}
              onChange={handleChange}
              error={findError("company", errors)}
              required
            />
          </div>
          <div className="col col-6">
            <EmaInput
              name="dba"
              label={t("Trade Name")}
              value={operator.dba}
              onChange={handleChange}
              error={findError("DBA", errors)}
            />
          </div>
          <div className="col col-6">
            <EmaInput
              type="select"
              options={["Carrier", "Forwarder", "Shipper", "Fleet Owner"]}
              name="authorityType"
              label={t("Authority Type")}
              value={operator.authorityType}
              onChange={handleChange}
              disabled={disableAuthorityType}
              error={findError("authorityType", errors)}
              required
            />
          </div>
          <div className="col col-6">
            <DateTimeInput
              type="date"
              value={operator.authorityStartDate}
              onChange={handleChange}
              name="authorityStartDate"
              label={t("Authority Start Date")}
              error={findError("authorityStartDate", errors)}
            />
          </div>

          <div className="col col-6">
            <EmaInput
              name="tin"
              label={t("TIN")}
              value={operator.tin}
              onChange={handleChange}
              error={findError("TIN", errors)}
              required
            />
          </div>
          <div className="col col-6">
            <EmaInput
              name="website"
              label={t("Website")}
              value={operator.website}
              onChange={handleChange}
              error={findError("Website", errors)}
            />
          </div>
        </div>
      </div>

      <div className="ema-card">
        <div className="ema-card-title">{t("Address")}</div>
        <div className="row">
          <div className="col col-12">
            <EmaInput
              name="addressLine1"
              label={t("Address 1")}
              value={operator.addressLine1}
              onChange={handleChange}
              error={findError("addressLine1", errors)}
            />
          </div>
          <div className="col col-12">
            <EmaInput
              name="addressLine2"
              label={t("Address 2")}
              value={operator.addressLine2}
              onChange={handleChange}
              error={findError("addressLine2", errors)}
            />
          </div>
          <div className="col col-6">
            <EmaInput
              name="city"
              label={t("City")}
              value={operator.city}
              onChange={handleChange}
              error={findError("city", errors)}
            />
          </div>
          <div className="col col-6">
            <CountryRegionInput
              onChange={handleChange}
              value={operator.state}
              name="state"
              error={findError("state", errors)}
              label={t("State")}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OperatorForm;
