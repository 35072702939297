export const chartData = [
  {
    label: "Commodity",
    title: "Import",
    data: [
      {
        label: "Machinery",
        value: "36.11%",
      },
      {
        label: "Mineral Fuels",
        value: "20.6%",
      },
      {
        label: "Vehicles",
        value: "16.23%",
      },
      {
        label: "Cooking Oil",
        value: "10.5%",
      },
      {
        label: "Iron & Steel",
        value: "6.37%",
      },
      {
        label: "Plastics",
        value: "3.11%",
      },
      {
        label: "pharmaceuticals",
        value: "3.68%",
      },
      {
        label: "Fertilizers",
        value: "3.4%",
      },
    ],
    colorCodes: [
      "#13405F",
      "#1A5781",
      "#216EA3",
      "#2885C5",
      "#429BD9",
      "#68AFE0",
      "#8FC4E8",
      "#B5D8F0",
    ],
  },
  {
    label: "Commodity",
    title: "Export",
    data: [
      {
        label: "Machinery",
        value: "36.11%",
      },
      {
        label: "Mineral Fuels",
        value: "20.6%",
      },
      {
        label: "Vehicles",
        value: "16.23%",
      },
      {
        label: "Cooking Oil",
        value: "10.5%",
      },
      {
        label: "Iron & Steel",
        value: "6.37%",
      },
      {
        label: "Plastics",
        value: "3.11%",
      },
      {
        label: "pharmaceuticals",
        value: "3.68%",
      },
      {
        label: "Fertilizers",
        value: "3.4%",
      },
    ],
    colorCodes: [
      "#064E3B",
      "#065F46",
      "#059669",
      "#10B981",
      "#34D399",
      "#6EE7B7",
      "#A7F3D0",
      "#D1FAE5",
    ],
  },
];
