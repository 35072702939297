import Dashboard from "view/dashboard/Dashboard";
import FreightForwarders from "view/freight-operators/FreightForwarders";
import IconsDisplay from "view/icons-display/IconsDisplay";
import Marketplace from "view/marketplace/Marketplace";
import OperationalActivity from "view/operational-activity/OperationalActivity";
import Vehicles from "view/vehicles/Vehicles";

const routes = [
  {
    path: "/",
    title: "Dashboard",
    component: Dashboard,
    showFor: "all",
    hasPermission: (hasPermission) => hasPermission(true),
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    component: Dashboard,
    showFor: "all",
    hasPermission: (hasPermission) => hasPermission(true),
  },

  {
    path: "/operational-activity",
    title: "Operational Activity",
    component: OperationalActivity,
    showFor: "all",
    hasPermission: (hasPermission) => hasPermission(true),
  },

  {
    path: "/freight-operators",
    title: "Freight Operators",
    component: FreightForwarders,
    showFor: "all",
    hasPermission: (hasPermission) => hasPermission(true),
  },

  {
    path: "/vehicles",
    title: "Vehicles",
    component: Vehicles,
    showFor: "all",
    hasPermission: (hasPermission) => hasPermission(true),
  },
  {
    path: "/Marketplace",
    title: "Marketplace",
    component: Marketplace,
    showFor: "all",
    hasPermission: (hasPermission) => hasPermission(true),
  },
  {
    path: "/icons",
    title: "Icons",
    component: IconsDisplay,
    showFor: "all",
    hasPermission: (hasPermission) => hasPermission(true),
  },
];

export default routes;
