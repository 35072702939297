import EmaButton from "components/emaButton/EmaButton";
import { useState } from "react";
import OperatorForm from "./OperatorForm";
import { postElement } from "services/apiEndpoints/Endpoints";
import { useTranslation } from "react-i18next";

const NewOperator = ({ setIsOpen, getOperators }) => {
  const { t } = useTranslation();
  const [operator, setOperator] = useState({});
  const [errors, setFormErrors] = useState([]);

  function handleChange(e) {
    setOperator({
      ...operator,
      [e.target.name]: e.target.value,
    });
  }

  function handleClickSave() {
    postElement(`/Authority/Operator`, operator).then((res) => {
      if (res.success) {
        getOperators();
        setIsOpen(false);
      } else if (res.vErrors) {
        setFormErrors(res.vErrors);
      }
    });
  }
  return (
    <div>
      <div className="slider-header">
        <section className="slider-header-title">
          {t("Freight Operator Registration")}
        </section>
        <section className="slider-header-buttons">
          <EmaButton title={t("Cancel")} onClick={() => setIsOpen(false)} />
          <EmaButton
            title={t("Save")}
            btnDark
            onClick={() => handleClickSave(operator)}
          />
        </section>
      </div>
      <div className="slider-body">
        <OperatorForm
          handleChange={handleChange}
          operator={operator}
          errors={errors}
          t={t}
        />
      </div>
    </div>
  );
};

export default NewOperator;
