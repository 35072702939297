import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const PieChartCard = ({ data }) => {
  const { t } = useTranslation();
  data.data.map((d) => d.value.slice(0, -1));
  const doughnutData = {
    labels: data.data.map((d) => d.label),
    datasets: [
      {
        label: "Revenue",
        data: ["36", "25", "24", "8", "7"],
        backgroundColor: [
          data.colorCodes[0],
          data.colorCodes[2],
          data.colorCodes[5],
          data.colorCodes[6],
          data.colorCodes[7],
        ],
        borderWidth: 0,
      },
    ],
  };
  const doughnutOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: 0,
  };

  return (
    <div className=" dashboard-card pie-chart-card">
      <section className="pie-chart-card_title">
        <label>{t(data.label)}</label>
        <strong>{t(data.title)}</strong>
      </section>
      <div className="pie-chart-card_body mt-5">
        <section className="pie-chart-circle">
          <Doughnut
            data={doughnutData}
            options={doughnutOptions}
            height="180px"
            width="180px"
          />
        </section>
        <ul className="pie-chart-circle_items">
          {data.data.map((d, i) => (
            <li>
              <span style={{ background: data.colorCodes[i] }}></span>
              <strong>{d.label}</strong>
              <label className="m-0 ml-3">{d.value}</label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PieChartCard;
