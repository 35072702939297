import EmaButton from "components/emaButton/EmaButton";
import React from "react";
import ActiveCard from "./dashboard-cards/ActiveCard";
import "./dashboard.sass";
import PieChartCard from "./dashboard-cards/PieChartCard";
import ImportExportCard from "./dashboard-cards/ImportExportCard";
import { chartData } from "./dashboard-cards/chartData";
import { getElements } from "services/apiEndpoints/Endpoints";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const [chartDashboard, setChartDashboard] = useState([]);
  const getDashboard = () => {
    getElements(`dashboard/main/actionable`).then((response) => {
      setChartDashboard(response.data);
    });
  };

  useEffect(getDashboard, []);
  return (
    <div className="dashboard">
      <div className="title-bar">
        <strong className="title-label">{t("Dashboard")}</strong>
        <div>
          <EmaButton
            title={"4 " + t("Weeks")}
            icon="mil-chevron-sm-down"
            reversed
          />
        </div>
      </div>
      <div className="dashboard-cards">
        <div className="dash-cards-flex-cont flex-column">
          <div className="dash-cards-flex-cont">
            <ActiveCard response={chartDashboard} />
          </div>
          <div className="dash-cards-flex-cont pie-charts-cont">
            {chartData.map((c) => (
              <PieChartCard data={c} />
            ))}
          </div>
        </div>
        <div
          className="dash-cards-flex-cont flex-column"
          style={{ width: "20%" }}
        >
          <ImportExportCard response={chartDashboard} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
